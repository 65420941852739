import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/HeroMain.js";
import Features from "components/features/FeaturesMain.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import GetStarted from "components/cta/GetStarted.js";
import Footer from "components/footers/FooterMain.js";
import heroScreenshotImageSrc from "images/work-table.avif";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-600`;
  const HighlightedText = tw.span`text-primary-600`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Características</Subheading>}
        heading={
          <>
            Temos Soluções <HighlightedText>Personalizadas.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Trabalho de qualidade</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      {/* <MainFeature2
        subheading={<Subheading>VALORES</Subheading>}
        heading={
          <>
            Sempre Respeitamos <HighlightedText>Nossos Princípios.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Acessível",
            description: "Prometemos oferecer-lhe a melhor tarifa possível - de acordo com o padrão da indústria.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Profissionalismo",
            description: "Garantimos que nossos modelos são projetados e criados por designers profissionais.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      /> */}
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
